var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"has-text-weight-bold is-size-4 mb-1 flowstep-title"},[_vm._v(" "+_vm._s(_vm.stepCopy.name)+" ")]),(_vm.stepCopy.description && _vm.stepCopy.description.length > 0)?_c('p',{staticClass:"mb-6 flowstep-description"},[_vm._v(" "+_vm._s(_vm.stepCopy.description)+" ")]):_vm._e(),(!_vm.loaded)?_c('section',{staticClass:"af-section"},_vm._l((3),function(ll){return _c('article',{key:ll,staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"},[_c('b-skeleton',{attrs:{"circle":"","width":"64px","height":"64px"}})],1)]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('p',[_c('b-skeleton',{attrs:{"active":""}}),_c('b-skeleton',{attrs:{"height":"80px"}})],1)]),_c('nav',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('a',{staticClass:"level-item"},[_c('span',{staticClass:"icon is-small"},[_c('b-skeleton')],1)]),_c('a',{staticClass:"level-item"},[_c('span',{staticClass:"icon is-small"},[_c('b-skeleton')],1)])])])])])}),0):_c('validation-observer',{ref:"flowObserver"},_vm._l((_vm.stepCopy.questions),function(question){return _c('div',{key:question.id},[_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[(
            question.fieldType === _vm.FLOW_QUESTION_FIELD_TYPE.SIMPLE_FIELD &&
            _vm.checkVisibility(question)
          )?_c('div',[(
              _vm.simpleFieldGetter(question.fieldId).type ===
                _vm.SIMPLE_FIELD_TYPE.INPUT &&
              (question.isRequired ||
                _vm.simpleFieldGetter(question.fieldId).validation)
            )?_c('BInputWithValidation',{attrs:{"id":("flowInput_" + (_vm.simpleFieldGetter(question.fieldId)
              .identifier.replaceAll(' ', '_')
              .toLowerCase())),"rules":_vm.getRules(question),"label":_vm.simpleFieldGetter(question.fieldId).label,"value":_vm.readAnswer(
                _vm.simpleFieldGetter(question.fieldId).identifier,
                _vm.simpleFieldGetter(question.fieldId).mask
              ),"mask":_vm.simpleFieldGetter(question.fieldId).mask},on:{"blur":function($event){_vm.writeAnswer(
                $event,
                _vm.simpleFieldGetter(question.fieldId).identifier,
                question.fieldId
              )}},nativeOn:{"keyup":function($event){_vm.refreshMask($event, _vm.simpleFieldGetter(question.fieldId).mask)}}}):_vm._e(),(
              _vm.simpleFieldGetter(question.fieldId).type ===
                _vm.SIMPLE_FIELD_TYPE.INPUT &&
              !(
                question.isRequired ||
                _vm.simpleFieldGetter(question.fieldId).validation
              )
            )?_c('b-field',{staticClass:"mb-6",attrs:{"label":_vm.simpleFieldGetter(question.fieldId).label}},[_c('b-input',{attrs:{"id":("flowInput_" + (_vm.simpleFieldGetter(question.fieldId)
                .identifier.replaceAll(' ', '_')
                .toLowerCase())),"value":_vm.readAnswer(
                  _vm.simpleFieldGetter(question.fieldId).identifier,
                  _vm.simpleFieldGetter(question.fieldId).mask
                )},on:{"blur":function($event){_vm.writeAnswer(
                  $event.currentTarget.value,
                  _vm.simpleFieldGetter(question.fieldId).identifier,
                  question.fieldId
                )}},nativeOn:{"keyup":function($event){_vm.refreshMask($event, _vm.simpleFieldGetter(question.fieldId).mask)}}})],1):_vm._e(),(
              _vm.simpleFieldGetter(question.fieldId).type ===
              _vm.SIMPLE_FIELD_TYPE.SELECT
            )?_c('BSelectWithValidation',{staticClass:"mb-6",attrs:{"id":("flowInput_" + (_vm.simpleFieldGetter(question.fieldId)
              .identifier.replaceAll(' ', '_')
              .toLowerCase())),"rules":question.isRequired ? 'required' : '',"label":_vm.simpleFieldGetter(question.fieldId).label,"value":_vm.readAnswer(_vm.simpleFieldGetter(question.fieldId).identifier)},on:{"input":function($event){_vm.writeAnswer(
                $event,
                _vm.simpleFieldGetter(question.fieldId).identifier,
                question.fieldId
              )}}},_vm._l((_vm.simpleFieldGetter(question.fieldId)
                .inputValues),function(iv,index){return _c('option',{key:index,attrs:{"id":("flowInput_" + (_vm.simpleFieldGetter(question.fieldId)
                .identifier.replaceAll(' ', '_')
                .toLowerCase()) + "_" + index)},domProps:{"value":iv.value}},[_vm._v(" "+_vm._s(iv.text)+" ")])}),0):_vm._e(),(
              _vm.simpleFieldGetter(question.fieldId).type ===
              _vm.SIMPLE_FIELD_TYPE.DATE_PICKER
            )?_c('BDatePickerWithValidation',{staticClass:"mb-6",attrs:{"id":("flowInput_" + (_vm.simpleFieldGetter(question.fieldId)
              .identifier.replaceAll(' ', '_')
              .toLowerCase())),"vid":("flowInput_" + (_vm.simpleFieldGetter(question.fieldId)
              .identifier.replaceAll(' ', '_')
              .toLowerCase())),"value":_vm.readAnswer(_vm.simpleFieldGetter(question.fieldId).identifier)
                ? _vm.readAnswer(
                    _vm.simpleFieldGetter(question.fieldId).identifier
                  ).toString().length === 7
                  ? _vm.readAnswer(_vm.simpleFieldGetter(question.fieldId).identifier)
                  : new Date(
                      _vm.readAnswer(
                        _vm.simpleFieldGetter(question.fieldId).identifier
                      )
                    )
                : null,"rules":{
              required: question.isRequired,
              validDate: question.isRequired,
              past: _vm.simpleFieldGetter(question.fieldId).validation === 'PAST',
              future:
                _vm.simpleFieldGetter(question.fieldId).validation === 'FUTURE',
            },"label":_vm.simpleFieldGetter(question.fieldId).label},on:{"input":function($event){_vm.writeAnswer($event, {
                identifier: _vm.simpleFieldGetter(question.fieldId).identifier,
                date: true,
              }),
                question.fieldId}}}):_vm._e(),(
              _vm.simpleFieldGetter(question.fieldId).type ===
              _vm.SIMPLE_FIELD_TYPE.RADIO
            )?_c('ApplicationFlowRadioQuestion',{attrs:{"is-mortgage":_vm.isMortgage,"field":_vm.simpleFieldGetter(question.fieldId),"value":_vm.readAnswer(_vm.simpleFieldGetter(question.fieldId).identifier),"isRequired":question.isRequired},on:{"input":_vm.writeAnswer}}):_vm._e(),(
              _vm.simpleFieldGetter(question.fieldId).type ===
              _vm.SIMPLE_FIELD_TYPE.CHECKBOX
            )?_c('ApplicationFlowCheckboxQuestion',{attrs:{"is-mortgage":_vm.isMortgage,"field":_vm.simpleFieldGetter(question.fieldId),"value":_vm.readAnswer(_vm.simpleFieldGetter(question.fieldId).identifier),"isRequired":question.isRequired},on:{"input":function($event){_vm.writeAnswer(
                $event,
                _vm.simpleFieldGetter(question.fieldId).identifier,
                question.fieldId
              )}}}):_vm._e()],1):_vm._e()]),(
          question.fieldType === _vm.FLOW_QUESTION_FIELD_TYPE.STATEMENT_PROMPT &&
          _vm.checkVisibility(question)
        )?_c('div',{staticClass:"mb-6"},[_c('ValidationProvider',{attrs:{"rules":{
            statementMustBeAccepted: question.isRequired,
            required: question.isRequired,
          },"vid":("" + (question.fieldId)),"mode":"lazy","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('p',{staticClass:"statement-label"},[_vm._v(" "+_vm._s(_vm.statementPromptGetter(question.fieldId).name)+" ")]),_c('div',{staticClass:"statement-tile is-flex is-align-items-center",class:{ 'is-invalid': errors[0] }},[(
                _vm.statementPromptGetter(question.fieldId).type ===
                _vm.STATEMENT_PROMPT_TYPE.DOCUMENT
              )?[_c('span',{staticClass:"cursor-pointer",attrs:{"id":("flowInput_" + (_vm.statementPromptGetter(question.fieldId)
                  .identifier.replaceAll(' ', '_')
                  .toLowerCase()))},on:{"click":function($event){_vm.downloadFile(_vm.statementPromptGetter(question.fieldId))}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon-pack":"fa","icon":"file-download","size":"is-medium","type":"is-blue"}})],1),_c('StatementReaderModal',{attrs:{"documentId":_vm.statementPromptGetter(question.fieldId).attachedDocument,"isRequiredToRead":_vm.statementPromptGetter(question.fieldId).isReadingObligatory,"isPublic":false,"label":_vm.statementPromptGetter(question.fieldId).label},on:{"accept":function($event){return _vm.acceptStatement(question)}}})]:_vm._e(),(
                _vm.statementPromptGetter(question.fieldId).type ===
                _vm.STATEMENT_PROMPT_TYPE.DYNAMIC
              )?[(_vm.dynamicStatementDocumentGetter(question.id).documentId)?[_c('span',{staticClass:"cursor-pointer",attrs:{"id":("flowInput_" + (_vm.statementPromptGetter(question.fieldId)
                    .identifier.replaceAll(' ', '_')
                    .toLowerCase()))},on:{"click":function($event){_vm.downloadFile({
                      attachedDocument: _vm.dynamicStatementDocumentGetter(
                        question.id
                      ).documentId,
                      name: _vm.dynamicStatementDocumentGetter(question.id)
                        .fileName,
                    })}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon-pack":"fa","icon":"file-download","size":"is-medium","type":"is-blue"}})],1),_c('StatementReaderModal',{attrs:{"documentId":_vm.dynamicStatementDocumentGetter(question.id).documentId,"isRequiredToRead":_vm.statementPromptGetter(question.fieldId)
                      .isReadingObligatory,"isPublic":false,"label":_vm.statementPromptGetter(question.fieldId).label},on:{"accept":function($event){return _vm.acceptStatement(question)}}})]:[_vm._v(_vm._s(_vm.$t("af:statement_reader.dynamic.empty")))]]:_vm._e(),(
                _vm.statementPromptGetter(question.fieldId).type ===
                _vm.STATEMENT_PROMPT_TYPE.LINK
              )?[(
                  _vm.statementPromptGetter(question.fieldId).type ===
                  _vm.STATEMENT_PROMPT_TYPE.LINK
                )?_c('StatementLink',{attrs:{"id":("flowInput_" + (_vm.statementPromptGetter(question.fieldId)
                  .identifier.replaceAll(' ', '_')
                  .toLowerCase())),"link":_vm.statementPromptGetter(question.fieldId).link,"isRequiredToRead":_vm.statementPromptGetter(question.fieldId).isReadingObligatory,"label":_vm.statementPromptGetter(question.fieldId).label},on:{"accept":function($event){return _vm.setPromptOpen(question.fieldId)}}}):_vm._e()]:_vm._e(),(!_vm.statementPromptGetter(question.fieldId).type)?[_c('label',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.statementPromptGetter(question.fieldId).label))])]:_vm._e(),(
                _vm.statementPromptGetter(question.fieldId).type ===
                _vm.STATEMENT_PROMPT_TYPE.DYNAMIC
              )?_c('div',{staticClass:"ml-auto"},[_c('b-field',[(
                    _vm.dynamicStatementDocumentGetter(question.id).documentId
                  )?_c('b-switch',{staticClass:"pl-2 ml-auto",attrs:{"id":("flowInput_" + (_vm.statementPromptGetter(question.fieldId)
                    .identifier.replaceAll(' ', '_')
                    .toLowerCase())),"type":"is-blue","disabled":_vm.statementPromptGetter(question.fieldId)
                      .isReadingObligatory && !_vm.openedPrompts[question.fieldId],"value":_vm.readAnswer(
                      _vm.statementPromptGetter(question.fieldId).identifier +
                        '_' +
                        question.id
                    )},on:{"input":function($event){_vm.saveAcceptance(
                      $event,
                      _vm.statementPromptGetter(question.fieldId),
                      question.isRequired,
                      _vm.statementPromptGetter(question.fieldId).type,
                      _vm.statementPromptGetter(question.fieldId).link,
                      question.id
                    )}}}):_vm._e()],1)],1):_c('div',{staticClass:"ml-auto"},[_c('b-field',[_c('div',{on:{"click":function($event){_vm.handleDisabledSwitchClick(
                      _vm.statementPromptGetter(question.fieldId)
                        .isReadingObligatory &&
                        !_vm.openedPrompts[question.fieldId]
                    )}}},[_c('b-switch',{staticClass:"pl-2 ml-auto",attrs:{"id":("flowInput_" + (_vm.statementPromptGetter(question.fieldId)
                      .identifier.replaceAll(' ', '_')
                      .toLowerCase())),"type":"is-blue","disabled":_vm.statementPromptGetter(question.fieldId)
                        .isReadingObligatory &&
                      !_vm.openedPrompts[question.fieldId],"value":_vm.readAnswer(
                        _vm.statementPromptGetter(question.fieldId).identifier
                      )},on:{"input":function($event){_vm.saveAcceptance(
                        $event,
                        _vm.statementPromptGetter(question.fieldId),
                        question.isRequired,
                        _vm.statementPromptGetter(question.fieldId).type,
                        _vm.statementPromptGetter(question.fieldId).link,
                        question.id
                      )}}})],1)])],1)],2),(errors[0])?_c('div',{staticClass:"field-error-message"},[_c('p',[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}],null,true)})],1):_vm._e(),(
          question.fieldType === _vm.FLOW_QUESTION_FIELD_TYPE.DOCUMENT_PROMPT &&
          _vm.checkVisibility(question)
        )?_c('div',[_c('BUploadWithValidation',{attrs:{"id":("flowInput_" + (_vm.documentPromptGetter(question.fieldId)
            .identifier.replaceAll(' ', '_')
            .toLowerCase())),"rules":question.isRequired &&
            !(_vm.isMortgage
              ? _vm.getMortgageFlowQuestionAnswer(
                  _vm.documentPromptGetter(question.fieldId).identifier
                )
              : _vm.getFlowQuestionAnswer(
                  _vm.documentPromptGetter(question.fieldId).identifier
                ))
              ? 'required'
              : '',"label":_vm.documentPromptGetter(question.fieldId).label,"prompt":_vm.documentPromptGetter(question.fieldId),"disabled":_vm.documentUploading},on:{"input":function($event){_vm.writeAnswer(
              $event,
              {
                identifier: _vm.documentPromptGetter(question.fieldId).identifier,
                file: true,
              },
              question.fieldId
            )},"documentUploadStart":_vm.documentUploadStart,"documentUploadFinish":function($event){_vm.documentUploadFinish(_vm.documentPromptGetter(question.fieldId).id)}}}),_c('br'),_c('ApplicationFlowDocumentPromptDownloader',{ref:"documentPromptDownloaders",refInFor:true,attrs:{"prompt-id":_vm.documentPromptGetter(question.fieldId).id},on:{"documentDeleteFinish":function($event){_vm.documentDeleteFinish(
              $event,
              _vm.documentPromptGetter(question.fieldId).identifier
            )}}})],1):_vm._e(),(
          question.fieldType === _vm.FLOW_QUESTION_FIELD_TYPE.READONLY_FIELD &&
          _vm.checkVisibility(question)
        )?_c('div',{staticClass:"mb-6"},[(_vm.readOnlyFieldGetter(question.fieldId).label)?_c('p',{staticClass:"is-size-7 has-text-grey-icon"},[_vm._v(" "+_vm._s(_vm.readOnlyFieldGetter(question.fieldId).label)+" ")]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(_vm.readOnlyFieldGetter(question.fieldId).description)}})]):_vm._e(),(
          question.fieldType === _vm.FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
          question.fieldSubtype === _vm.FLOW_QUESTION_FIELD_SUBTYPE.PSD2 &&
          _vm.checkVisibility(question)
        )?_c('Psd2Field',{staticClass:"mb-6",attrs:{"label":_vm.psd2FieldGetter(question.fieldId).label,"description":_vm.psd2FieldGetter(question.fieldId).description,"skippable":!question.isRequired,"source":_vm.PSD2_FIELD_SOURCE.LOAN_APPLICATION,"fieldId":question.fieldId,"flowId":_vm.flowId,"loanApplicationId":_vm.loanApplicationId},on:{"synced":_vm.synced}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.psd2FieldGetter(question.fieldId).content)}})]):_vm._e(),(
          question.fieldType === _vm.FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
          question.fieldSubtype === _vm.FLOW_QUESTION_FIELD_SUBTYPE.BARRIER &&
          _vm.checkVisibility(question)
        )?_c('BarrierField',{attrs:{"question-id":question.id,"field-id":question.fieldId,"is-mortgage":_vm.isMortgage,"loan-application-id":_vm.loanApplicationId,"mortgage-application-id":_vm.mortgageApplicationId}}):_vm._e(),(
          question.fieldType === _vm.FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
          question.fieldSubtype === _vm.FLOW_QUESTION_FIELD_SUBTYPE.KYC &&
          _vm.checkVisibility(question)
        )?_c('KycField',{attrs:{"question-id":question.id,"field-id":question.fieldId,"kyc-data":question.kycData}}):_vm._e(),(
          question.fieldType === _vm.FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
          question.fieldSubtype ===
            _vm.FLOW_QUESTION_FIELD_SUBTYPE.REVIEW_SCORE &&
          _vm.checkVisibility(question)
        )?_c('ApplicationFlowStarQuestion',{attrs:{"question":_vm.psd2FieldGetter(question.fieldId).label,"value":_vm.readAnswer(_vm.psd2FieldGetter(question.fieldId).identifier)},on:{"input":function($event){_vm.writeAnswer(
            $event,
            _vm.psd2FieldGetter(question.fieldId).identifier,
            question.fieldId
          )}}}):_vm._e(),(
          question.fieldType === _vm.FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
          question.fieldSubtype === _vm.FLOW_QUESTION_FIELD_SUBTYPE.REVIEW_TEXT &&
          _vm.checkVisibility(question)
        )?_c('b-field',{staticClass:"mb-6",attrs:{"label":_vm.psd2FieldGetter(question.fieldId).label}},[_c('b-input',{attrs:{"type":"textarea","id":("flowInput_" + (_vm.psd2FieldGetter(question.fieldId)
            .identifier.replaceAll(' ', '_')
            .toLowerCase())),"value":_vm.readAnswer(_vm.psd2FieldGetter(question.fieldId).identifier)},on:{"blur":function($event){_vm.writeAnswer(
              $event.currentTarget.value,
              _vm.psd2FieldGetter(question.fieldId).identifier,
              question.fieldId
            )}}})],1):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }