<template>
  <ValidationProvider
    :vid="vid"
    :ref="vid"
    :name="$attrs.label"
    :rules="rules"
    v-slot="{ errors, validated, valid }"
  >
    <b-field
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0], 'is-success': validated && valid }"
      :message="errors"
      class="validator-field"
    >
      <b-datepicker
        locale="hu-HU"
        icon-pack="fa"
        icon="calendar"
        trap-focus
        :mobile-native="false"
        :years-range="[-100, 60]"
        v-model="innerValue"
        expanded
        :focused-date="
          withoutDay
            ? new Date(value)
            : innerValue
            ? new Date(innerValue)
            : new Date()
        "
        :placeholder="/* withoutDay ? value : ''*/ ''"
      />
    </b-field>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: null,
  }),
  watch: {
    // Handles internal model changes.
    async innerValue(newVal) {
      if (newVal !== this.value) {
        this.$emit("input", newVal);
      }
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  computed: {
    withoutDay() {
      return this.value?.toString().length === 7;
    },
  },
  created() {
    if (this.value && !this.withoutDay) {
      this.innerValue = this.value;
    }
  },
};
</script>
