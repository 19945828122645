<template>
  <div>
    <p class="has-text-weight-bold is-size-4 mb-1 flowstep-title">
      {{ stepCopy.name }}
    </p>
    <p
      v-if="stepCopy.description && stepCopy.description.length > 0"
      class="mb-6 flowstep-description"
    >
      {{ stepCopy.description }}
    </p>

    <section class="af-section" v-if="!loaded">
      <article class="media" v-for="ll in 3" :key="ll">
        <figure class="media-left">
          <p class="image is-64x64">
            <b-skeleton circle width="64px" height="64px"></b-skeleton>
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <b-skeleton active></b-skeleton>
              <b-skeleton height="80px"></b-skeleton>
            </p>
          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              <a class="level-item">
                <span class="icon is-small">
                  <b-skeleton></b-skeleton>
                </span>
              </a>
              <a class="level-item">
                <span class="icon is-small">
                  <b-skeleton></b-skeleton>
                </span>
              </a>
            </div>
          </nav>
        </div>
      </article>
    </section>

    <validation-observer v-else ref="flowObserver">
      <div v-for="question in stepCopy.questions" :key="question.id">
        <!--Simple fields-->
        <transition name="fade" mode="in-out">
          <div
            v-if="
              question.fieldType === FLOW_QUESTION_FIELD_TYPE.SIMPLE_FIELD &&
              checkVisibility(question)
            "
          >
            <!-- Input with validation -->
            <BInputWithValidation
              :id="`flowInput_${simpleFieldGetter(question.fieldId)
                .identifier.replaceAll(' ', '_')
                .toLowerCase()}`"
              @keyup.native="
                refreshMask($event, simpleFieldGetter(question.fieldId).mask)
              "
              v-if="
                simpleFieldGetter(question.fieldId).type ===
                  SIMPLE_FIELD_TYPE.INPUT &&
                (question.isRequired ||
                  simpleFieldGetter(question.fieldId).validation)
              "
              :rules="getRules(question)"
              :label="simpleFieldGetter(question.fieldId).label"
              :value="
                readAnswer(
                  simpleFieldGetter(question.fieldId).identifier,
                  simpleFieldGetter(question.fieldId).mask
                )
              "
              :mask="simpleFieldGetter(question.fieldId).mask"
              @blur="
                writeAnswer(
                  $event,
                  simpleFieldGetter(question.fieldId).identifier,
                  question.fieldId
                )
              "
            />

            <!-- Input without Validation -->
            <b-field
              v-if="
                simpleFieldGetter(question.fieldId).type ===
                  SIMPLE_FIELD_TYPE.INPUT &&
                !(
                  question.isRequired ||
                  simpleFieldGetter(question.fieldId).validation
                )
              "
              :label="simpleFieldGetter(question.fieldId).label"
              class="mb-6"
            >
              <b-input
                :id="`flowInput_${simpleFieldGetter(question.fieldId)
                  .identifier.replaceAll(' ', '_')
                  .toLowerCase()}`"
                @keyup.native="
                  refreshMask($event, simpleFieldGetter(question.fieldId).mask)
                "
                :value="
                  readAnswer(
                    simpleFieldGetter(question.fieldId).identifier,
                    simpleFieldGetter(question.fieldId).mask
                  )
                "
                @blur="
                  writeAnswer(
                    $event.currentTarget.value,
                    simpleFieldGetter(question.fieldId).identifier,
                    question.fieldId
                  )
                "
              ></b-input>
            </b-field>

            <!--Select-->
            <BSelectWithValidation
              :id="`flowInput_${simpleFieldGetter(question.fieldId)
                .identifier.replaceAll(' ', '_')
                .toLowerCase()}`"
              v-if="
                simpleFieldGetter(question.fieldId).type ===
                SIMPLE_FIELD_TYPE.SELECT
              "
              :rules="question.isRequired ? 'required' : ''"
              :label="simpleFieldGetter(question.fieldId).label"
              :value="
                readAnswer(simpleFieldGetter(question.fieldId).identifier)
              "
              @input="
                writeAnswer(
                  $event,
                  simpleFieldGetter(question.fieldId).identifier,
                  question.fieldId
                )
              "
              class="mb-6"
            >
              <option
                v-for="(iv, index) in simpleFieldGetter(question.fieldId)
                  .inputValues"
                :id="`flowInput_${simpleFieldGetter(question.fieldId)
                  .identifier.replaceAll(' ', '_')
                  .toLowerCase()}_${index}`"
                :value="iv.value"
                :key="index"
              >
                {{ iv.text }}
              </option>
            </BSelectWithValidation>

            <!--Date picker-->

            <BDatePickerWithValidation
              :id="`flowInput_${simpleFieldGetter(question.fieldId)
                .identifier.replaceAll(' ', '_')
                .toLowerCase()}`"
              :vid="`flowInput_${simpleFieldGetter(question.fieldId)
                .identifier.replaceAll(' ', '_')
                .toLowerCase()}`"
              :value="
                readAnswer(simpleFieldGetter(question.fieldId).identifier)
                  ? readAnswer(
                      simpleFieldGetter(question.fieldId).identifier
                    ).toString().length === 7
                    ? readAnswer(simpleFieldGetter(question.fieldId).identifier)
                    : new Date(
                        readAnswer(
                          simpleFieldGetter(question.fieldId).identifier
                        )
                      )
                  : null
              "
              @input="
                writeAnswer($event, {
                  identifier: simpleFieldGetter(question.fieldId).identifier,
                  date: true,
                }),
                  question.fieldId
              "
              v-if="
                simpleFieldGetter(question.fieldId).type ===
                SIMPLE_FIELD_TYPE.DATE_PICKER
              "
              :rules="{
                required: question.isRequired,
                validDate: question.isRequired,
                past: simpleFieldGetter(question.fieldId).validation === 'PAST',
                future:
                  simpleFieldGetter(question.fieldId).validation === 'FUTURE',
              }"
              :label="simpleFieldGetter(question.fieldId).label"
              class="mb-6"
            />

            <!--Radio-->
            <ApplicationFlowRadioQuestion
              v-if="
                simpleFieldGetter(question.fieldId).type ===
                SIMPLE_FIELD_TYPE.RADIO
              "
              :is-mortgage="isMortgage"
              :field="simpleFieldGetter(question.fieldId)"
              :value="
                readAnswer(simpleFieldGetter(question.fieldId).identifier)
              "
              @input="writeAnswer"
              :isRequired="question.isRequired"
            />

            <!--Checkbox-->
            <ApplicationFlowCheckboxQuestion
              v-if="
                simpleFieldGetter(question.fieldId).type ===
                SIMPLE_FIELD_TYPE.CHECKBOX
              "
              :is-mortgage="isMortgage"
              :field="simpleFieldGetter(question.fieldId)"
              :value="
                readAnswer(simpleFieldGetter(question.fieldId).identifier)
              "
              @input="
                writeAnswer(
                  $event,
                  simpleFieldGetter(question.fieldId).identifier,
                  question.fieldId
                )
              "
              :isRequired="question.isRequired"
            />
          </div>
        </transition>

        <!--Statement prompt-->
        <div
          v-if="
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.STATEMENT_PROMPT &&
            checkVisibility(question)
          "
          class="mb-6"
        >
          <ValidationProvider
            :rules="{
              statementMustBeAccepted: question.isRequired,
              required: question.isRequired,
            }"
            :vid="`${question.fieldId}`"
            v-slot="{ errors }"
            mode="lazy"
            slim
          >
            <p class="statement-label">
              {{ statementPromptGetter(question.fieldId).name }}
            </p>
            <div
              class="statement-tile is-flex is-align-items-center"
              :class="{ 'is-invalid': errors[0] }"
            >
              <template
                v-if="
                  statementPromptGetter(question.fieldId).type ===
                  STATEMENT_PROMPT_TYPE.DOCUMENT
                "
              >
                <span
                  :id="`flowInput_${statementPromptGetter(question.fieldId)
                    .identifier.replaceAll(' ', '_')
                    .toLowerCase()}`"
                  @click="downloadFile(statementPromptGetter(question.fieldId))"
                  class="cursor-pointer"
                >
                  <b-icon
                    icon-pack="fa"
                    icon="file-download"
                    size="is-medium"
                    type="is-blue"
                    class="mr-2"
                  ></b-icon>
                </span>

                <StatementReaderModal
                  :documentId="
                    statementPromptGetter(question.fieldId).attachedDocument
                  "
                  :isRequiredToRead="
                    statementPromptGetter(question.fieldId).isReadingObligatory
                  "
                  :isPublic="false"
                  :label="statementPromptGetter(question.fieldId).label"
                  @accept="acceptStatement(question)"
                />
              </template>
              <template
                v-if="
                  statementPromptGetter(question.fieldId).type ===
                  STATEMENT_PROMPT_TYPE.DYNAMIC
                "
              >
                <template
                  v-if="dynamicStatementDocumentGetter(question.id).documentId"
                >
                  <span
                    :id="`flowInput_${statementPromptGetter(question.fieldId)
                      .identifier.replaceAll(' ', '_')
                      .toLowerCase()}`"
                    @click="
                      downloadFile({
                        attachedDocument: dynamicStatementDocumentGetter(
                          question.id
                        ).documentId,
                        name: dynamicStatementDocumentGetter(question.id)
                          .fileName,
                      })
                    "
                    class="cursor-pointer"
                  >
                    <b-icon
                      icon-pack="fa"
                      icon="file-download"
                      size="is-medium"
                      type="is-blue"
                      class="mr-2"
                    ></b-icon>
                  </span>

                  <StatementReaderModal
                    :documentId="
                      dynamicStatementDocumentGetter(question.id).documentId
                    "
                    :isRequiredToRead="
                      statementPromptGetter(question.fieldId)
                        .isReadingObligatory
                    "
                    :isPublic="false"
                    :label="statementPromptGetter(question.fieldId).label"
                    @accept="acceptStatement(question)"
                  />
                </template>
                <template v-else>{{
                  $t("af:statement_reader.dynamic.empty")
                }}</template>
              </template>

              <template
                v-if="
                  statementPromptGetter(question.fieldId).type ===
                  STATEMENT_PROMPT_TYPE.LINK
                "
              >
                <StatementLink
                  :id="`flowInput_${statementPromptGetter(question.fieldId)
                    .identifier.replaceAll(' ', '_')
                    .toLowerCase()}`"
                  v-if="
                    statementPromptGetter(question.fieldId).type ===
                    STATEMENT_PROMPT_TYPE.LINK
                  "
                  :link="statementPromptGetter(question.fieldId).link"
                  :isRequiredToRead="
                    statementPromptGetter(question.fieldId).isReadingObligatory
                  "
                  @accept="setPromptOpen(question.fieldId)"
                  :label="statementPromptGetter(question.fieldId).label"
                />
              </template>

              <template v-if="!statementPromptGetter(question.fieldId).type">
                <label class="mr-2">{{
                  statementPromptGetter(question.fieldId).label
                }}</label>
              </template>

              <div
                class="ml-auto"
                v-if="
                  statementPromptGetter(question.fieldId).type ===
                  STATEMENT_PROMPT_TYPE.DYNAMIC
                "
              >
                <b-field>
                  <b-switch
                    v-if="
                      dynamicStatementDocumentGetter(question.id).documentId
                    "
                    :id="`flowInput_${statementPromptGetter(question.fieldId)
                      .identifier.replaceAll(' ', '_')
                      .toLowerCase()}`"
                    class="pl-2 ml-auto"
                    type="is-blue"
                    :disabled="
                      statementPromptGetter(question.fieldId)
                        .isReadingObligatory && !openedPrompts[question.fieldId]
                    "
                    :value="
                      readAnswer(
                        statementPromptGetter(question.fieldId).identifier +
                          '_' +
                          question.id
                      )
                    "
                    @input="
                      saveAcceptance(
                        $event,
                        statementPromptGetter(question.fieldId),
                        question.isRequired,
                        statementPromptGetter(question.fieldId).type,
                        statementPromptGetter(question.fieldId).link,
                        question.id
                      )
                    "
                  />
                </b-field>
              </div>

              <div class="ml-auto" v-else>
                <b-field>
                  <div
                    @click="
                      handleDisabledSwitchClick(
                        statementPromptGetter(question.fieldId)
                          .isReadingObligatory &&
                          !openedPrompts[question.fieldId]
                      )
                    "
                  >
                    <b-switch
                      :id="`flowInput_${statementPromptGetter(question.fieldId)
                        .identifier.replaceAll(' ', '_')
                        .toLowerCase()}`"
                      class="pl-2 ml-auto"
                      type="is-blue"
                      :disabled="
                        statementPromptGetter(question.fieldId)
                          .isReadingObligatory &&
                        !openedPrompts[question.fieldId]
                      "
                      :value="
                        readAnswer(
                          statementPromptGetter(question.fieldId).identifier
                        )
                      "
                      @input="
                        saveAcceptance(
                          $event,
                          statementPromptGetter(question.fieldId),
                          question.isRequired,
                          statementPromptGetter(question.fieldId).type,
                          statementPromptGetter(question.fieldId).link,
                          question.id
                        )
                      "
                    />
                  </div>
                </b-field>
              </div>
            </div>
            <div v-if="errors[0]" class="field-error-message">
              <p>{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>

        <!--Document upload-->
        <div
          v-if="
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.DOCUMENT_PROMPT &&
            checkVisibility(question)
          "
        >
          <BUploadWithValidation
            :id="`flowInput_${documentPromptGetter(question.fieldId)
              .identifier.replaceAll(' ', '_')
              .toLowerCase()}`"
            :rules="
              question.isRequired &&
              !(isMortgage
                ? getMortgageFlowQuestionAnswer(
                    documentPromptGetter(question.fieldId).identifier
                  )
                : getFlowQuestionAnswer(
                    documentPromptGetter(question.fieldId).identifier
                  ))
                ? 'required'
                : ''
            "
            :label="documentPromptGetter(question.fieldId).label"
            :prompt="documentPromptGetter(question.fieldId)"
            @input="
              writeAnswer(
                $event,
                {
                  identifier: documentPromptGetter(question.fieldId).identifier,
                  file: true,
                },
                question.fieldId
              )
            "
            @documentUploadStart="documentUploadStart"
            @documentUploadFinish="
              documentUploadFinish(documentPromptGetter(question.fieldId).id)
            "
            :disabled="documentUploading"
          />
          <br />
          <ApplicationFlowDocumentPromptDownloader
            :prompt-id="documentPromptGetter(question.fieldId).id"
            ref="documentPromptDownloaders"
            @documentDeleteFinish="
              documentDeleteFinish(
                $event,
                documentPromptGetter(question.fieldId).identifier
              )
            "
          />
        </div>

        <!--Read only field-->
        <div
          v-if="
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.READONLY_FIELD &&
            checkVisibility(question)
          "
          class="mb-6"
        >
          <p
            v-if="readOnlyFieldGetter(question.fieldId).label"
            class="is-size-7 has-text-grey-icon"
          >
            {{ readOnlyFieldGetter(question.fieldId).label }}
          </p>
          <p v-html="readOnlyFieldGetter(question.fieldId).description" />
        </div>

        <!--PSD2 field-->
        <Psd2Field
          v-if="
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
            question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.PSD2 &&
            checkVisibility(question)
          "
          class="mb-6"
          :label="psd2FieldGetter(question.fieldId).label"
          :description="psd2FieldGetter(question.fieldId).description"
          :skippable="!question.isRequired"
          :source="PSD2_FIELD_SOURCE.LOAN_APPLICATION"
          :fieldId="question.fieldId"
          :flowId="flowId"
          :loanApplicationId="loanApplicationId"
          @synced="synced"
        >
          <span v-html="psd2FieldGetter(question.fieldId).content"></span>
        </Psd2Field>

        <!-- Barrier field -->
        <BarrierField
          v-if="
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
            question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.BARRIER &&
            checkVisibility(question)
          "
          :question-id="question.id"
          :field-id="question.fieldId"
          :is-mortgage="isMortgage"
          :loan-application-id="loanApplicationId"
          :mortgage-application-id="mortgageApplicationId"
        />

        <!-- KYC field -->
        <KycField
          v-if="
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
            question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.KYC &&
            checkVisibility(question)
          "
          :question-id="question.id"
          :field-id="question.fieldId"
          :kyc-data="question.kycData"
        />

        <!-- Review Fileds -->
        <ApplicationFlowStarQuestion
          v-if="
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
            question.fieldSubtype ===
              FLOW_QUESTION_FIELD_SUBTYPE.REVIEW_SCORE &&
            checkVisibility(question)
          "
          :question="psd2FieldGetter(question.fieldId).label"
          :value="readAnswer(psd2FieldGetter(question.fieldId).identifier)"
          @input="
            writeAnswer(
              $event,
              psd2FieldGetter(question.fieldId).identifier,
              question.fieldId
            )
          "
        />

        <b-field
          v-if="
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
            question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.REVIEW_TEXT &&
            checkVisibility(question)
          "
          :label="psd2FieldGetter(question.fieldId).label"
          class="mb-6"
        >
          <b-input
            type="textarea"
            :id="`flowInput_${psd2FieldGetter(question.fieldId)
              .identifier.replaceAll(' ', '_')
              .toLowerCase()}`"
            :value="readAnswer(psd2FieldGetter(question.fieldId).identifier)"
            @blur="
              writeAnswer(
                $event.currentTarget.value,
                psd2FieldGetter(question.fieldId).identifier,
                question.fieldId
              )
            "
          ></b-input>
        </b-field>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import {
  CONDITIONAL_FIELD_OPERATOR,
  FLOW_QUESTION_FIELD_SUBTYPE,
  FLOW_QUESTION_FIELD_TYPE,
  PSD2_FIELD_SOURCE,
  SIMPLE_FIELD_TYPE,
  STATEMENT_PROMPT_TYPE,
} from "@/utils/const";
import { formatDate, maskValue } from "@/utils/util";
import Vue from "vue";
import FileSaver from "file-saver";
import eventBus, { EVENTS } from "@/utils/eventBus";
import BDatePickerWithValidation from "@/components/BDatePickerWithValidation.vue";
import i18n from "@/plugins/i18n";

export default {
  name: "ApplicationFlowStep",
  components: {
    BDatePickerWithValidation,
    BarrierField: () => import("@/components/BarrierField.vue"),
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
    ApplicationFlowDocumentPromptDownloader: () =>
      import(
        "@/components/applicationflow/ApplicationFlowDocumentPromptDownloader.vue"
      ),
    BSelectWithValidation: () =>
      import("@/components/BSelectWithValidation.vue"),
    BUploadWithValidation: () =>
      import("@/components/BUploadWithValidation.vue"),
    ApplicationFlowRadioQuestion: () =>
      import("@/components/applicationflow/ApplicationFlowRadioQuestion.vue"),
    ApplicationFlowCheckboxQuestion: () =>
      import(
        "@/components/applicationflow/ApplicationFlowCheckboxQuestion.vue"
      ),
    StatementReaderModal: () => import("@/components/StatementReaderModal.vue"),
    StatementLink: () => import("@/components/StatementLink.vue"),
    Psd2Field: () => import("@/components/psd2/Psd2Field.vue"),
    KycField: () => import("@/components/KycField.vue"),
    ApplicationFlowStarQuestion: () =>
      import("./ApplicationFlowStarQuestion.vue"),
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
    flowId: {
      type: [Number, String],
      required: false,
    },
    loanApplicationId: {
      type: [Number, String],
      required: false,
    },
    mortgageApplicationId: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      stepCopy: null,
      loaded: false,
      documentUploading: false,
      FLOW_QUESTION_FIELD_TYPE,
      FLOW_QUESTION_FIELD_SUBTYPE,
      SIMPLE_FIELD_TYPE,
      STATEMENT_PROMPT_TYPE,
      PSD2_FIELD_SOURCE,
      openedPrompts: {},
      hasDocumentPrompt: false,
      hasPsd2Field: false,
      psd2FieldId: null,
      psd2Synced: false,
    };
  },
  computed: {
    ...mapGetters({
      dynamicStatementDocumentGetter:
        "mortgageApplication/getDynamicStatementAttachment",
      simpleFieldGetter: "simpleFieldStore/get",
      documentPromptGetter: "documentPromptStore/get",
      statementPromptGetter: "statementPromptStore/get",
      readOnlyFieldGetter: "readOnlyFieldStore/get",
      psd2FieldGetter: "specialFieldStore/get",
      getFlowQuestionAnswer: "loanApplication/getFlowQuestionAnswer",
      getMortgageFlowQuestionAnswer:
        "mortgageApplication/getFlowQuestionAnswer",
      fileGetter: "drawer/getFile",
    }),
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
  },
  created() {
    eventBus.$on(EVENTS.FLOW_VALIDATION, this.validate);
  },
  destroyed() {
    eventBus.$off(EVENTS.FLOW_VALIDATION);
  },
  watch: {
    step: {
      deep: true,
      immediate: true,
      handler: async function () {
        this.loaded = false;
        this.stepCopy = cloneDeep(this.step);
        this.stepCopy.questions.sort((a, b) => a.rowIndex - b.rowIndex);

        this.hasDocumentPrompt = false;
        this.hasPsd2Field = false;

        const promises = [];
        this.stepCopy.questions.forEach((question) => {
          if (question.fieldType === FLOW_QUESTION_FIELD_TYPE.SIMPLE_FIELD) {
            promises.push(this.getSimpleField(question.fieldId));
          } else if (
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.DOCUMENT_PROMPT
          ) {
            promises.push(this.getDocumentPrompt(question.fieldId));
            this.hasDocumentPrompt = true;
            this.documentPromptId = question.fieldId;
          } else if (
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.STATEMENT_PROMPT
          ) {
            promises.push(this.getStatementPrompt(question.fieldId));
            if (this.isMortgage) {
              promises.push(this.fetchDynamicStatementDocument(question.id));
            }
          } else if (
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.READONLY_FIELD
          ) {
            promises.push(this.getReadOnlyField(question.fieldId));
          } else if (
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
            question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.PSD2
          ) {
            promises.push(this.getPsd2Field(question.fieldId));
            this.hasPsd2Field = true;
            this.psd2FieldId = question.fieldId;
          } else if (
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
            (question.fieldSubtype ===
              FLOW_QUESTION_FIELD_SUBTYPE.REVIEW_SCORE ||
              question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.REVIEW_TEXT)
          ) {
            promises.push(this.getPsd2Field(question.fieldId));
          }
        });

        await Promise.all(promises);

        // Set already accepted statements
        this.stepCopy.questions.forEach((question) => {
          if (
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.STATEMENT_PROMPT
          ) {
            const answer = this.readAnswer(
              this.statementPromptGetter(question.fieldId).identifier
            );

            if (answer) {
              this.setPromptOpen(question.fieldId);
            }
          }
        });

        for (const question of this.stepCopy.questions) {
          if (question.isConditional && question.conditions) {
            for (const condition of question.conditions) {
              if (!this.simpleFieldGetter(condition.conditionalFieldId)) {
                await this.getSimpleField(condition.conditionalFieldId);
              }
            }
          }
        }

        this.loaded = true;
      },
    },
  },
  methods: {
    ...mapActions({
      getSimpleField: "simpleFieldStore/fetchOne",
      getDocumentPrompt: "documentPromptStore/fetchOne",
      getReadOnlyField: "readOnlyFieldStore/fetchOne",
      getPsd2Field: "specialFieldStore/fetchOne",
      getStatementPrompt: "statementPromptStore/fetchOne",
      saveDynamicData: "loanApplication/saveDynamicData",
      removeDynamicData: "loanApplication/removeDynamicData",
      saveMortgageDynamicData: "mortgageApplication/saveDynamicData",
      removeMortgageDynamicData: "mortgageApplication/removeDynamicData",
      fetchDynamicStatementDocument:
        "mortgageApplication/getDynamicStatementAttachment",
      download: "drawer/download",
    }),
    synced() {
      this.psd2Synced = true;
    },
    documentUploadStart() {
      this.documentUploading = true;
    },
    documentUploadFinish(promptId) {
      setTimeout(() => {
        this.documentUploading = false;

        let documentPromptDownloaders = this.$refs.documentPromptDownloaders;
        let documentPromptDownloader = documentPromptDownloaders.find(
          (documentPromptDownloader) =>
            documentPromptDownloader.promptId === promptId
        );

        if (documentPromptDownloader) {
          documentPromptDownloader.loadDocuments();
        }
      }, 2000);
    },
    async documentDeleteFinish(hasDocuments, promptIdentifier) {
      if (!hasDocuments) {
        if (this.isMortgage) {
          await this.saveMortgageDynamicData({
            key: promptIdentifier,
            value: hasDocuments,
          });
        } else {
          await this.saveDynamicData({
            key: promptIdentifier,
            value: hasDocuments,
          });
        }
      }
    },
    async validate() {
      let flowObserver = this.$refs.flowObserver;
      if (flowObserver) {
        await flowObserver.validate();
      }
    },
    setPromptOpen(promptId) {
      Vue.set(this.openedPrompts, promptId, true);
    },
    acceptStatement(question) {
      Vue.set(this.openedPrompts, question.fieldId, true);
      this.saveAcceptance(
        true,
        this.statementPromptGetter(question.fieldId),
        question.isRequired,
        this.statementPromptGetter(question.fieldId).type,
        this.statementPromptGetter(question.fieldId).link,
        question.id
      );
    },
    refreshMask(event, mask) {
      let value = event.target.value;
      let maskedValue = maskValue(mask, value);
      event.target.value = maskedValue;
    },
    writeAnswer(value, identifier, fieldId) {
      if (typeof identifier !== "string" && identifier.date) {
        value = formatDate(value);
        identifier = identifier.identifier;
      }
      if (typeof identifier !== "string" && identifier.file) {
        value = true;
        identifier = identifier.identifier;
      }

      if (fieldId) {
        for (const question of this.stepCopy.questions) {
          if (question.isConditional && question.conditions) {
            for (const condition of question.conditions) {
              if (
                condition.conditionalFieldId === fieldId &&
                condition.conditionalFieldAnswer !== value
              ) {
                this.removeAnswer(
                  this.getFieldIdentifier(question),
                  question.fieldId
                );
              }
            }
          }
        }
      }
      if (this.isMortgage) {
        this.saveMortgageDynamicData({
          key: identifier,
          value: value,
        });
      } else {
        this.saveDynamicData({
          key: identifier,
          value: value,
        });
      }
    },
    getFieldIdentifier(question) {
      let field;
      if (question.fieldType === FLOW_QUESTION_FIELD_TYPE.SIMPLE_FIELD) {
        field = this.simpleFieldGetter(question.fieldId);
      } else if (
        question.fieldType === FLOW_QUESTION_FIELD_TYPE.DOCUMENT_PROMPT
      ) {
        field = this.documentPromptGetter(question.fieldId);
      } else if (
        question.fieldType === FLOW_QUESTION_FIELD_TYPE.STATEMENT_PROMPT
      ) {
        field = this.statementPromptGetter(question.fieldId);
      } else if (
        question.fieldType === FLOW_QUESTION_FIELD_TYPE.READONLY_FIELD
      ) {
        field = this.readOnlyFieldGetter(question.fieldId);
      } else if (
        question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
        question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.PSD2
      ) {
        field = this.psd2FieldGetter(question.fieldId);
      }

      return field?.identifier;
    },
    removeAnswer(identifier, fieldId) {
      if (fieldId) {
        for (const question of this.stepCopy.questions) {
          if (question.isConditional && question.conditions) {
            for (const condition of question.conditions) {
              if (condition.conditionalFieldId === fieldId) {
                this.removeAnswer(
                  this.getFieldIdentifier(question),
                  question.fieldId
                );
              }
            }
          }
        }
      }

      if (this.isMortgage) {
        this.removeMortgageDynamicData(identifier);
      } else {
        this.removeDynamicData(identifier);
      }
    },
    getRules(question) {
      let rules = { required: question.isRequired };
      let regex = this.simpleFieldGetter(question.fieldId).validation;
      if (regex) {
        rules["regex"] = regex;
      }
      return rules;
    },
    readAnswer(identifier, mask) {
      return maskValue(
        mask,
        this.isMortgage
          ? this.$store.getters["mortgageApplication/getFlowQuestionAnswer"](
              identifier
            )
          : this.$store.getters["loanApplication/getFlowQuestionAnswer"](
              identifier
            )
      );
    },
    async saveAcceptance(
      checked,
      statement,
      isRequired,
      type,
      link,
      questionId
    ) {
      try {
        let request = {
          statementId: statement.id,
          statementIdentifier: statement.identifier,
          statementLabel: statement.label,
          statementTitle: statement.name,
          isRequired: !!isRequired,
          documentId: this.getDocumentId(statement, type, questionId),
          fileName: this.getFileName(statement, type, questionId),
          link: type === STATEMENT_PROMPT_TYPE.LINK ? link : null,
          isDynamicStatement: STATEMENT_PROMPT_TYPE.DYNAMIC === statement.type,
          flowQuestionId: questionId,
          isAccepted: checked,
          layoutType: "FLOW",
          flowIdentifier: this.stepCopy.name,
        };
        await this.$store.dispatch(
          "acceptanceRequirements/acceptStatement",
          request
        );
        let promptIdentifier = statement.identifier;
        if (STATEMENT_PROMPT_TYPE.DYNAMIC === statement.type) {
          promptIdentifier += "_" + questionId;
        }
        this.writeAnswer(checked, promptIdentifier);
      } catch (ex) {
        console.error("Statement Acceptance failed!");
      }
    },
    handleDisabledSwitchClick(disabled) {
      if (disabled) {
        this.$buefy.toast.open({
          message: i18n.t(
            "af:flow_step.message.validator.read_statement_first"
          ),
          type: "is-danger",
          position: "is-bottom",
        });
      }
    },
    getDocumentId(statement, type, questionId) {
      if (type === STATEMENT_PROMPT_TYPE.DOCUMENT) {
        return statement.attachedDocument;
      }
      if (type === STATEMENT_PROMPT_TYPE.DYNAMIC) {
        return this.dynamicStatementDocumentGetter(questionId).documentId;
      }

      return null;
    },
    getFileName(statement, type, questionId) {
      if (type === STATEMENT_PROMPT_TYPE.DYNAMIC) {
        return this.dynamicStatementDocumentGetter(questionId).fileName;
      }
      return null;
    },
    checkVisibility(question) {
      if (question.hidden) {
        return false;
      }
      if (!question.isConditional) {
        return true;
      } else {
        let visible;
        for (const condition of question.conditions) {
          let index = question.conditions.indexOf(condition);
          let evaluate = this.evaluateCondition(condition);
          if (index === 0) {
            visible = evaluate;
          } else {
            let operator =
              question.conditions[index - 1].conditionalFieldOperator;
            if (CONDITIONAL_FIELD_OPERATOR.ENUM.AND === operator) {
              visible = visible && evaluate;
            } else if (CONDITIONAL_FIELD_OPERATOR.ENUM.OR === operator) {
              visible = visible || evaluate;
            }
          }
        }

        return visible;
      }
    },
    evaluateCondition(condition) {
      let dependency = this.simpleFieldGetter(condition.conditionalFieldId);
      const answer = this.isMortgage
        ? this.getMortgageFlowQuestionAnswer(dependency.identifier)
        : this.getFlowQuestionAnswer(dependency.identifier);

      // The checkbox answer is an array, not a single string
      if (dependency.type === SIMPLE_FIELD_TYPE.CHECKBOX) {
        if (answer) {
          let answerObject = JSON.parse(answer);
          return answerObject.includes(condition.conditionalFieldAnswer);
        } else {
          return false;
        }
      } else {
        return answer === condition.conditionalFieldAnswer;
      }
    },
    async downloadFile({ attachedDocument: id, name }) {
      await this.download(id);
      let blob = this.fileGetter(id);
      FileSaver.saveAs(blob, name);
    },
    getFieldErrors(errors, fieldId) {
      fieldId = fieldId + "";
      if (errors[fieldId]) return errors[fieldId];
      return [];
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

div.is-invalid {
  outline: 1px solid #f14668;
}

.field-error-message {
  color: #f14668;
  margin-top: 4px;
  font-size: 12px;
  position: absolute;
}

.statement-tile {
  background: #f3f5f9;
  border-radius: 8px;
  text-align: center;
  padding: 24px;
}

.statement-label {
  color: #616161;
  font-size: 13px;
}

.mbh {
  .flowstep-title {
    font-size: 32px !important;
    font-weight: 800;
    color: #212121;
    margin-bottom: 32px !important;
  }

  .flowstep-description {
    color: #051c3a;
    font-size: 16px;
  }
}
</style>
